@import 'node_modules/bootswatch/dist/flatly/_variables.scss';
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/bootswatch/dist/flatly/_bootswatch.scss';

.lg-max-vh-100 {
  @include media-breakpoint-up(lg) {
    max-height: 100vh !important;
  }
}

.status-error {
  color: #fff;
  background-color: #6610f2 !important;
}
.status-warning {
  color: #fff;
  background-color: #F39C12 !important;
}
